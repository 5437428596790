header {
    /* margin-top: 5rem; */
    margin-top:0;
    height: calc(100vh - 5rem);
    display: grid;
    place-items: center;
    background: var(--color-white);
    transition: var(--transition);
}

.header__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

/* .header__profile {
    width: 18rem;
    height: 22rem;
    border-radius: 9rem 9rem 2rem 2rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
} */

.header__container p {
    width: 35rem;
    text-align: center;
    margin: 2rem 0 2rem;
}

.header__cta {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.header__socials {
    position: absolute;
    /* left: 0; */
    right: 0;
    /* bottom: 0; */
    top:0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
/* .header__socials::after{
    content:"";
    width: 2px;
    height: 2rem;
    right: -1rem;
    top:5rem;
    background: var(--color-black);
} */

.header__socials a {
    width: 2rem;
    aspect-ratio: 1/1;
    background: var(--color-light);
    color: var(--color-black);
    display: grid;
    place-items: center;
    border-radius: var(--border-radius-3);
}

.header__socials a:hover {
    background: var(--color-primary);
    color: var(--color-white);
    transform: translateX(-0.5rem);
}
/* =============frames (1)======*/
.header__frames {
    position: absolute;
    top: 22vh;
    /* right: 15rem; */
    left:0;
    transition: var(--transition);
  
}

.header__frame {
    width: 10rem;
    border: 0.4rem solid var(--color-bg-3);
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.5);
    position: absolute;
    transform: rotate(-10deg);
    /* transform: rotate(-10deg); */
    transform-origin: bottom left;
    transition: var(--transition);
}

.header__frame:nth-child(2) {
    transform: rotate(10deg);
    /* top: -2rem; */
    /* left: 2rem; */
    top: 5rem;
    left: 9rem;
    /* bottom: 0; */
    /* top: -5rem;
    left: 11rem;
    bottom: 0; */
}

.header__frames:hover .header__frame {
    transform: rotate(0);
}
.header__frames:hover{
    top: 30vh;
    /* right: 15rem; */
  
}

.header__frames:hover .header__frame:nth-child(2) {
    /* top: 0; */
    /* left: 15rem; */
    top: 4.5rem;
    left: 12rem;
}
/*============= frame-deux ======*/
.header__frames-deux {
    position: absolute;
    top: 39vh;
    right: 21rem;
    transition: var(--transition);
}

.header__frame-un {
    width: 14rem;
    border: 0.4rem solid var(--color-bg-3);
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.5);
    position: absolute;
    transform: rotate(-20deg);
    transform-origin: bottom left;
    transition: var(--transition);
}

.header__frame-un:nth-child(2) {
    /* transform: rotate(20deg);
    top: -2rem;
    left: 2rem; */

    top: -5rem;
    left: 12rem;
    bottom: 0;
}

.header__frames-deux:hover .header__frame-un {
    transform: rotate(0);
}

.header__frames-deux:hover .header__frame-un:nth-child(2) {
    top: 0;
    left: 15rem;
}




/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    header {
        
        margin-top: 0;
        height: 100vh;
        
    }
   


    /* =============frames (1)======*/
.header__frames {
    position: absolute;
    top: -25vh;
    /* right: 15rem; */
    left:10rem;
    transition: var(--transition);
  
}

.header__frame {
    width: 10rem;
    border: 0.4rem solid var(--color-bg-3);
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.5);
    position: absolute;
    transform: rotate(-10deg);
    /* transform: rotate(-10deg); */
    transform-origin: bottom left;
    transition: var(--transition);
}

.header__frame:nth-child(2) {
    transform: rotate(10deg);
    /* top: -2rem; */
    /* left: 2rem; */
    top: 5rem;
    left: 9rem;
    /* bottom: 0; */
    /* top: -5rem;
    left: 11rem;
    bottom: 0; */
}

.header__frames:hover .header__frame {
    transform: rotate(0);
}
.header__frames:hover{
    /* top: 25vh; */
    top: -20rem;
    left: 12rem;
    /* right: 15rem; */
  
}

/*============= frame-deux ======*/
.header__frames-deux {
    position: absolute;
    top: 40vh;
    right: 40rem;
    transition: var(--transition);
}

.header__frame-un {
    width: 14rem;
    border: 0.4rem solid var(--color-bg-3);
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.5);
    position: absolute;
    transform: rotate(-20deg);
    transform-origin: bottom left;
    transition: var(--transition);
}

.header__frame-un:nth-child(2) {
    /* transform: rotate(20deg);
    top: -2rem;
    left: 2rem; */

    top: -5rem;
    left: 11rem;
    bottom: 0;
}

.header__frames-deux:hover .header__frame-un {
    transform: rotate(0);
}

.header__frames-deux:hover .header__frame-un:nth-child(2) {
    top: 0;
    left: 15rem;
}

   
}


/* MEDIA QUERIES (phone) */
@media screen and (max-width: 600px) {
    .header__profile {
        width: 14rem;
        height: 17rem;
        max-width: 60%;
        max-height: 35vh;
    }



    .header__container p {
        width: 100%;
    }

    .header__cta {
        gap: 1rem;
    }

    .header__socials {
        display: none;
    }
     .header__frames {
        display: none;
      
    }
     .header__frames-deux {
        display: none;
      
    }
}