#testimonials {
    padding-bottom: 0;
   
}

.swiper {
    height: 30rem;
    /* this paddint top is to give space for micro interaction on hover of swiper slide */
    padding-top: 1rem;
  
}

.swiper-slide {

    cursor: grab;
}

.light{
    display: grid;
    grid-template-columns: 30% 60%;
    gap:10%;
}

.testimonial__client {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}

.testimonial__client-avatar {
    width: 2.5rem;
    aspect-ratio: 1/1;
    border-radius: var(--border-radius-2);
    overflow: hidden;
    transition: var(--transition);
}

.swiper-pagination {
    margin-bottom: 8rem;
}


.swiper-pagination-bullet {
    background: var(--color-primary);
}

/* MEDIA QUERIES (medium device) */
@media screen and (max-width:1024px) {
    .light{
     
        grid-template-columns: 1fr;
      
    }
}