.prices__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 4rem;

}
h4{
    text-align: center;
    color: var(--color-primary);
    margin: 1rem;
}


.card.price {
    display: flex;
    justify-content: fit-content;
    align-items: center;
    transition: var(--transition);  
    margin-top:5rem;     
}

.price__details{
    
    /* display: grid;
    grid-template-columns: 40% 60%;
    justify-content: space-around;
    align-items: center; */
}


.price__details h4{
   
   line-height: 2rem;
   text-align: center;
   justify-content: center;
  
}

.price__details h3{
   margin-bottom: 1rem;
   line-height: 2rem;
   text-align: center;
   justify-content: center;
   color: var(--color-primary);
  
}



.price:hover  {
    background: var(--color-light);
    color: var(--color-primary);
}

.price h4 {
    margin-bottom: 0.7rem;
}



/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    .prices__container {
        gap: 1.5rem;
    }
}


/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .prices__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }

    .card.price {
        gap: 1.2rem;
    }
}